import qs from "query-string";
import loggerServiceInstance from "./utilities/loggerService";
import { getSingleManifestItem } from "./actions";
import responseErrorService from "./utilities/responseErrorHandling";
import { CONDITION_FILTER_TYPE, PLATFORM_INTIGRATION_TYPE } from "./constants";
import dayjs from "dayjs";
import { DISPLAY_FULL_DATETIME_FORMAT } from "./global_constants";
import { message } from "antd";

export const DollarFormatter = (value) => {
  return `$ ${roundNumber(value, 2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const PercentageFormatter = (value) => {
  const parsedValue = parseFloat(value.toString().replace(/[%\s,]+/g, ""));
  if (isNaN(parsedValue)) return "";
  return `${parsedValue.toLocaleString()} %`;
};
export const IntegerFormatter = (value) => {
  try {
    return Number.parseInt(value);
  } catch {
    return 1;
  }
};
export const DollarParser = (value) => {
  return value.replace(/\$\s?|(,*)/g, "");
};
export const IntegerParser = (value) => {
  return value.replace(/(,*)/g, "");
};
export const PercentageParser = (value) => {
  return value.toString().replace(/[%\s,]+/g, "");
};
export const updateUrlQueryString = (newValues, search, history) => {
  const values = qs.parse(search);
  const newQsValue = qs.stringify({
    ...values,
    ...newValues,
  });

  // check if the new query string is the same as the current one
  // if it is, do not push a new state
  // doing this will prevent the browser from adding a new history entry - which is not needed
  if (newQsValue === qs.stringify(values)) {
    loggerServiceInstance.log("updateUrlQueryString: new query string is the same as the current one");
    return;
  }

  history.push({
    search: newQsValue,
    pathname: history.location.pathname,
  });
};

export const IncrementSection = (s) => {
  // first case - string is empty: return "a"
  if (!s || s.length === 0) {
    return "a";
  }

  // last char is less than 'z': simply increment last char
  let lastChar = s[s.length - 1];
  let fragment = s.substring(0, s.length - 1);
  if (lastChar < "z") {
    lastChar = String.fromCharCode(lastChar.charCodeAt(0) + 1);
    return fragment + lastChar;
  }
  // last char is 'z': roll over and increment preceding string
  return IncrementSection(fragment) + "a";
};

export const IncrementLot = (s, incrementLotBy) => {
  if (!s || s.length === 0) {
    return 1;
  }

  let lot = parseInt(s);
  lot += incrementLotBy;
  return lot;
};

export const roundNumber = (number, decimals = 12) => {
  var newNumber = Number(number + "").toFixed(parseInt(decimals));
  return parseFloat(newNumber);
};

export const HardReloadFrontend = () => {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration.unregister();
    });
  });
  caches.keys().then((keyList) => {
    return Promise.all(
      keyList.map((key) => {
        return caches.delete(key);
      })
    );
  });
  setTimeout(() => {
    window.location.reload();
  }, 900);
};

export const CapitalizeEachArrayElement = (array) => {
  return array.map((item) => {
    return item.charAt(0).toUpperCase() + item.slice(1);
  });
};

export const IsValidURL = (s) => {
  try {
    new URL(s);
    return true;
  } catch (_) {
    return false;
  }
};
export const GetLastUsedDiscountPercentage = () => {
  return localStorage.getItem("lastUsedDiscountPercentage") || 0;
};

export const SetLastUsedDiscountPercentage = (percentage) => {
  localStorage.setItem("lastUsedDiscountPercentage", percentage);
};

export const getManifestItemForEdit = (manifestItemId, setLoading, setManifestItem) => {
  setLoading(true);
  getSingleManifestItem(manifestItemId)
    .then((response) => {
      setLoading(false);
      const { data } = response;
      const { manifest_item_specification: manifestItemSpecification } = data;

      let percentageOffRetail = null;
      try {
        percentageOffRetail = roundNumber(
          ((manifestItemSpecification.offerprice - manifestItemSpecification.discount_sale_price) / manifestItemSpecification.offerprice) * 100,
          2
        );
      } catch {}

      let manifestItemNew = {
        ...data,
        was_title_edit_forbidden: data.is_title_shortening_active,
        was_description_edit_forbidden: data.is_description_being_generated,
      };

      // if we have the manifest item specification, we will add it to the manifest item object
      if (manifestItemSpecification) {
        manifestItemNew = {
          ...manifestItemNew,
          manifest_item_specification: {
            ...manifestItemSpecification,
            percentage_off_retail: percentageOffRetail,
          },
        };
      }

      setManifestItem(manifestItemNew);
    })
    .catch((err) => {
      setLoading(false);
      responseErrorService.handle(err);
    });
};
export const platformIntigrationOptions = (blacklist = []) => {
  return Object.entries(PLATFORM_INTIGRATION_TYPE)
    .filter(([, label]) => !blacklist.includes(label))
    .map(([value, label]) => ({
      value: Number(value),
      label,
    }));
};
export const renderFormattedDate = (text) => {
  if (!text) return "-";
  return dayjs(text).format(DISPLAY_FULL_DATETIME_FORMAT);
};
export const handleCopyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    message.success("Copied to clipboard!");
  } catch (err) {
    message.error("Failed to copy text!");
  }
};
export const getSpecificValuesChangeHandler = (signal, keys, callback) => {
  const changedValues = {};

  Object.keys(signal).forEach((key) => {
    if (keys.includes(key)) {
      changedValues[key] = signal[key];
    }
  });

  if (Object.keys(changedValues).length > 0) {
    if (callback && typeof callback === "function") {
      callback(changedValues);
    }
  }
};
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const removeKeysFromObject = (obj, keysToRemove) => {
  for (const key of keysToRemove) {
    delete obj[key];
  }
};

export const nicelyFormatPascalCaseString = (camelCaseString, overrideInvalidStringValue = "N/A") => {
  if (camelCaseString === null || camelCaseString === undefined) return overrideInvalidStringValue;
  return (
    camelCaseString
      // insert a space before all caps
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
};

export const getContrastColor = (bgColor) => {
  // Convert hex color to RGB
  const r = parseInt(bgColor.substr(1, 2), 16);
  const g = parseInt(bgColor.substr(3, 2), 16);
  const b = parseInt(bgColor.substr(5, 2), 16);

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return black or white depending on luminance
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
};

export const ZPLTemplatesOptionsHideDeletedMap = (zplTemplates) => {
  return (zplTemplates || [])
    .filter((template) => !template.is_deleted)
    .map((template) => {
      return {
        label: template.name,
        value: template.id,
      };
    });
};

export const updateShowHiddenQueryString = (isShowHidden, filteringAttributeString, paramName = "show_hidden") => {
  if (isShowHidden && !filteringAttributeString?.includes(paramName)) {
    let prefix = "";
    if (filteringAttributeString) prefix = "&";
    filteringAttributeString = `${filteringAttributeString ?? ""}${prefix}${paramName}=true`;
  }

  return filteringAttributeString;
};

export const conditionFiltersMap = (conditionFilter) => {
  return {
    label: `${CapitalizeEachArrayElement(conditionFilter.param_name.split(",")).join(", ")} -> ${
      CONDITION_FILTER_TYPE[conditionFilter.filter_type]
    } -> ${conditionFilter.param_value}`,
    value: conditionFilter.id,
  };
};
